import React, { useEffect, useCallback, useLayoutEffect } from 'react'
import { useNavigation } from '@react-navigation/native'
import { View, ActivityIndicator } from 'react-native'
import { useDispatch, useSelector } from 'react-redux'
import { useTheme } from 'styled-components/native'
import PillwayAuthenticationError from 'APP/Containers/PillwayPortalScreen/PillwayAuthenticationError'
import PatientActions from 'APP/Redux/PatientRedux'
import { getServiceGroupIcon } from 'APP/Lib/CtaInfoHelpers'
import DialogueWebView from 'APP/Components/DialogueWebView'
import useServiceData from 'APP/Hooks/useServiceData'
import { ResponsiveView } from 'APP/Converse/Layout'

const PillwayPortalScreen = ({ route }) => {
  const dispatch = useDispatch()
  const navigation = useNavigation()
  const theme = useTheme()

  const {
    navigationUrl,
    serviceGroup,
    id: serviceGroupId,
    healthResource: serviceId,
  } = route?.params || {}

  const {
    serviceGroup: serviceGroupData,
    service: serviceData,
    loading: serviceDataLoading,
    error: serviceDataError,
  } = useServiceData(serviceGroupId, serviceId)

  const pillwayUrl = navigationUrl || serviceData?.navigationUrl
  const serviceGroupInfo = serviceGroup || serviceGroupData

  const serviceGroupNotLoaded = !!(
    !navigationUrl &&
    serviceGroupId &&
    !serviceGroupData &&
    !serviceDataError
  )

  const requestPillwayAuthentication = useCallback(() => {
    dispatch(PatientActions.pillwayAuthenticationRequest())
  }, [dispatch])

  useEffect(() => {
    requestPillwayAuthentication()
  }, [requestPillwayAuthentication])

  const screenIconOverride = getServiceGroupIcon(serviceGroupInfo?.screenIconOverride)
  const { pillway } = useSelector((state) => state.patient)
  const { running, accessToken, error } = pillway || {}

  useLayoutEffect(() => {
    const handleNavigate = () => {
      navigation.goBack()
    }
    navigation.setOptions({
      rightButtonIcon: 'close',
      rightButtonIconVariant: 'material',
      rightButtonOnPress: handleNavigate,
    })
  }, [navigation, serviceGroupInfo?.id])

  // Loading state
  if (!pillway || running || serviceDataLoading || serviceGroupNotLoaded) {
    return (
      <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }} testID="LOADING">
        <ActivityIndicator size="large" color={theme.colors.text} testID="LOADING_INDICATOR" />
      </View>
    )
  }

  // Error state, show authentication error component
  if (!accessToken || !pillwayUrl || error) {
    return (
      <PillwayAuthenticationError
        data-testid="PILLWAY_ERROR_SCREEN"
        onRetry={requestPillwayAuthentication}
        screenIconOverride={screenIconOverride}
        serviceGroup={serviceGroup}
      />
    )
  }

  const url = new URL(pillwayUrl)
  url.searchParams.set('access_token', accessToken)
  const uriWithAccessToken = url.toString()

  // Render requested URL
  return (
    <ResponsiveView style={{ flex: 1 }} testID="PILLWAY_PORTAL_SCREEN">
      <DialogueWebView uri={uriWithAccessToken} userSelect="none" />
    </ResponsiveView>
  )
}

export default PillwayPortalScreen
