import React, { useState, useCallback, useLayoutEffect, useMemo, useEffect } from 'react'
import { View, ScrollView, LayoutAnimation, BackHandler, Platform } from 'react-native'
import { useNavigation } from '@react-navigation/native'
import Icon from 'react-native-vector-icons/MaterialIcons'
import { useDispatch, useSelector } from 'react-redux'
import { useTheme } from 'styled-components/native'

// Utils
import { isAndroid } from 'APP/Helpers/checkPlatform'

// Components
import ProgressBar from 'APP/Components/ProgressBar'
import PatientHistoryActions from 'APP/Redux/PatientHistoryRedux'
import IntakeQuestion from './IntakeQuestion'
import IntakeReplyPanel from './IntakeReplyPanel'
import DialogueTouchableOpacity from 'APP/Components/DialogueTouchableOpacity'
import Alert from 'APP/Converse/Alert'
import { INTAKE_MESSAGE_TYPES } from './IntakeReplyForm'
import IntakeCancelChat from './IntakeCancelChat'
// Styles
import { Styles } from './style'

import Analytics from 'APP/Services/Analytics'
import I18n from 'APP/Services/i18n'
import sprigClient from 'APP/Services/Sprig'
import { isWeb } from 'APP/Helpers/checkPlatform'
import { ResponsiveView } from 'APP/Converse/Layout'
import IntakePulsatingView from './IntakePulsatingView'
import {
  checkIsPrescriptionFlow,
  checkIsGynOrSexualHealthFlow,
  captureUndefinedMessages,
} from 'APP/Helpers/Intake'
import { PortalPreview } from 'APP/Helpers/Portal'
import { ARCHIVE, UNDO } from './constants'
import { IntakeProvider, useIntakeContext } from './Context/IntakeProvider'

export { default as IntakeTransition } from './IntakeTransition'
export { IntakeReplyPanelInline } from './IntakeReplyPanel'
export { REPLY_FORMS as INTAKE_REPLY_FORMS, INTAKE_MESSAGE_TYPES } from './IntakeReplyForm'

const trackSprigSurvey = (questionPost, isPrescriptionFlow, isGynOrSexualHealthFlow) => {
  let trackName = 'MX_DROPOFF_INTAKE'
  if (isPrescriptionFlow) {
    trackName = 'MX_NC_PRESCRIPTION_DROPOFF'
  } else if (isGynOrSexualHealthFlow) {
    trackName = 'MX_GYN_DROPOFF'
  }

  sprigClient.track(trackName, {
    intake_utterance: questionPost?.props?.utterance,
    intake_message: questionPost?.message,
  })
}

const hasExitAlert = (exitAlert) => exitAlert && Object.keys(exitAlert).length

const onIntakeExit = ({
  exitAlert,
  navigation,
  questionPost,
  enableArchiveEpisode,
  isIntakeCancelable,
  isPrescriptionFlow,
  isGynOrSexualHealthFlow,
}) => {
  if (enableArchiveEpisode && !hasExitAlert(exitAlert) && isIntakeCancelable) {
    return
  }

  const exitText = exitAlert?.exit || I18n.t('Intake.alert.exit')
  const continueText = exitAlert?.continue || I18n.t('Intake.alert.continue')
  const exit = {
    text: exitText,
    onPress: () => {
      Analytics.trackEvent('button_click', {
        button_value: 'Intake exit',
        button_text: exitText,
      })
      navigation.goBack()
      trackSprigSurvey(questionPost, isPrescriptionFlow, isGynOrSexualHealthFlow)
    },
  }
  const continueTriage = {
    text: continueText,
    onPress: () => {
      Analytics.trackEvent('button_click', {
        button_value: 'Intake continue',
        button_text: continueText,
      })
    },
  }
  const options = Platform.OS === 'android' ? [exit, continueTriage] : [continueTriage, exit]

  Alert.alert(
    exitAlert?.title || I18n.t('Intake.alert.title'),
    exitAlert?.description || I18n.t('Intake.alert.description'),
    options
  )
  // Ensures Alert stays in place until user decides their action
  return true
}

const getLayoutConfig = ({ props: { type } = {} } = {}) => ({
  // shrinkCard === true:
  // * screen is not scrollable (does not support overflow, available screen space is divided between children)
  shrinkCard: ![
    INTAKE_MESSAGE_TYPES.prompt_choice_multi,
    INTAKE_MESSAGE_TYPES.prompt_multi_subprompt,
    !isWeb() && INTAKE_MESSAGE_TYPES.prompt_provider_list,
  ].includes(type),
  // shrinkReplyPanel === true:
  // * IntakeReplyPanel takes up the least amount of space
  shrinkReplyPanel: ![
    INTAKE_MESSAGE_TYPES.prompt_text,
    INTAKE_MESSAGE_TYPES.prompt_choice_multi,
    !isWeb() && INTAKE_MESSAGE_TYPES.prompt_choice_search,
    !isWeb() && INTAKE_MESSAGE_TYPES.prompt_choice_image_map,
    INTAKE_MESSAGE_TYPES.prompt_booking,
    !isWeb() && INTAKE_MESSAGE_TYPES.prompt_address,
  ].includes(type),
  // replyPanelNoGrow === true:
  // * IntakeReplyPanel would not be growing to take up space
  // * answers are always rendered in absolute overlay
  // tips:
  // * use when ReplyForm needs to take all available space (+ overlayReplyForm === true)
  replyPanelNoGrow: [
    isWeb() && INTAKE_MESSAGE_TYPES.prompt_choice_image_map,
    isWeb() && INTAKE_MESSAGE_TYPES.prompt_choice_search,
    isWeb() && INTAKE_MESSAGE_TYPES.prompt_address,
  ].includes(type),
  // overlayReplyForm === true:
  // * replyForm is rendered INLINE & OUTSIDE of replyPanel as the very last & direct child of <Card />
  overlayReplyForm: [
    INTAKE_MESSAGE_TYPES.prompt_choice_search,
    INTAKE_MESSAGE_TYPES.prompt_choice_image_map,
    INTAKE_MESSAGE_TYPES.prompt_booking,
    INTAKE_MESSAGE_TYPES.prompt_address,
  ].includes(type),
})

const Header = ({ processing, fullscreenState: { progress }, onExit, onGoBack, canGoBack }) => {
  const theme = useTheme()

  return (
    <ResponsiveView style={Styles.headerContainer}>
      <ProgressBar processing={processing} show={!!progress} {...progress} />

      <View
        style={[Styles.iconContainer, { justifyContent: canGoBack ? 'space-between' : 'flex-end' }]}
      >
        {canGoBack && (
          <DialogueTouchableOpacity
            style={Styles.iconButton}
            onPress={onGoBack}
            analyticsName="Intake go back"
            testID="intakeBackBtn"
            accessibilityLabel={I18n.t('AccessibilityIcons.back')}
          >
            <Icon
              name="arrow-back-ios-new"
              color={theme.colors.text}
              size={theme.fonts.fontSize.h3}
            />
          </DialogueTouchableOpacity>
        )}

        <DialogueTouchableOpacity
          style={Styles.iconButton}
          onPress={onExit}
          analyticsName="Intake close"
          testID="intakeCloseBtn"
          accessibilityLabel={I18n.t('AccessibilityIcons.close')}
        >
          <Icon name="close" color={theme.colors.text} size={theme.fonts.fontSize.h2} />
        </DialogueTouchableOpacity>
      </View>
    </ResponsiveView>
  )
}

const Card = ({ shrink, children }) => {
  const { scrollViewRef } = useIntakeContext()

  return (
    <ScrollView
      style={Styles.card}
      ref={scrollViewRef}
      contentContainerStyle={[Styles.cardContent, shrink && Styles.cardContentShrink]}
      keyboardShouldPersistTaps={true}
      alwaysBounceVertical={false}
      stickyHeaderIndices={[0]}
    >
      {children}
    </ScrollView>
  )
}
const Intake = ({
  posts,
  order,
  userId,
  fullscreenState = {},
  onIntakeAnswerSend,
  retryPost,
  resolveAttachmentUrl,
  resolveGetPostFileMetadata,
  error,
  exitAlert,
  channelId,
  exitAction,
}) => {
  const navigation = useNavigation()
  const [questionShown, setQuestionShown] = useState(false)
  const onQuestionShow = useCallback(() => setQuestionShown(true))

  const { enableArchiveEpisode, enableIntakeBackButton } = useSelector((state) => state.features)
  const { archiveEpisodeRunning, undonePostIds } = useSelector((state) => state.history)

  const [displayCancelChatModal, triggerCancelChatModal] = useState(false)

  const episode = useSelector((state) => state.history.episodes?.[channelId])

  const dispatch = useDispatch()

  const [questionPost, answerPosts] = useMemo(() => {
    const lastQuestionIndex = order.findIndex((id) => posts[id].user_id !== userId)
    const lastQuestionPost = posts[order[lastQuestionIndex]]
    let lastAnswerPosts = []

    if (lastQuestionIndex > 0) {
      lastAnswerPosts = order
        .slice(0, lastQuestionIndex)
        .map((id) => posts[id])
        // Filter failed posts not related to current question as they end up on the top of the episode
        .filter(({ create_at }) => create_at > lastQuestionPost.create_at)
    }
    return [lastQuestionPost, lastAnswerPosts]
  }, [posts, order, userId])

  const {
    isPrescriptionFlow,
    isGynOrSexualHealthFlow,
    isIntakeCancelable,
    backButtonAction,
    canGoBack,
  } = useMemo(() => {
    const isEpisodeArchivable = !!episode?.isArchivable
    const isBackButtonEnabled = questionPost?.props?.member_app_fullscreen?.back?.enabled ?? false

    return {
      isPrescriptionFlow: checkIsPrescriptionFlow(posts, questionPost),
      isGynOrSexualHealthFlow: checkIsGynOrSexualHealthFlow(posts),
      // In case of new episodes, isArchivable will be undefined so we default to true to make the chat cancellable
      isIntakeCancelable: isEpisodeArchivable,
      // Values: 'ARCHIVE', 'UNDO'
      backButtonAction: questionPost?.props?.member_app_fullscreen?.back?.app_action ?? 'UNDO',
      canGoBack: enableIntakeBackButton && isEpisodeArchivable && isBackButtonEnabled,
    }
  }, [posts, questionPost, episode, enableIntakeBackButton])

  const onExit = useCallback(() => {
    if (exitAction) {
      exitAction()
    } else {
      triggerCancelChatModal(isIntakeCancelable)
      onIntakeExit({
        exitAlert,
        navigation,
        questionPost,
        enableArchiveEpisode,
        isIntakeCancelable,
        isPrescriptionFlow,
        isGynOrSexualHealthFlow,
      })
    }
    return true // Prevent default back behavior
  }, [
    exitAlert,
    navigation,
    questionPost,
    enableArchiveEpisode,
    exitAction,
    isIntakeCancelable,
    isPrescriptionFlow,
    isGynOrSexualHealthFlow,
  ])

  const onCloseModal = () => {
    triggerCancelChatModal(false)
  }

  const onSaveChat = () => {
    navigation.goBack()
    trackSprigSurvey(questionPost)
  }

  const onArchiveEpisode = () => {
    let sprigSurveyData = {}
    if (isGynOrSexualHealthFlow) {
      sprigSurveyData = {
        trackName: 'MX_GYN_DROPOFF',
        eventProperties: {
          intake_utterance: questionPost?.props?.utterance,
          intake_message: questionPost?.message,
        },
      }
    }
    dispatch(PatientHistoryActions.archiveEpisodeRequest(channelId, sprigSurveyData))
    trackSprigSurvey(questionPost)
  }

  const horizontalAnimation = {
    duration: 0,
    create: {
      type: LayoutAnimation.Types.linear,
      property: LayoutAnimation.Properties.opacity,
    },
    update: {
      type: LayoutAnimation.Types.linear,
      property: LayoutAnimation.Properties.opacity,
    },
    delete: {
      type: LayoutAnimation.Types.linear,
      property: LayoutAnimation.Properties.opacity,
    },
  }

  useEffect(() => {
    if (displayCancelChatModal) {
      Analytics.trackScreen('Intake ask cancel chat modal')
    }
  }, [displayCancelChatModal])

  useEffect(() => {
    BackHandler.addEventListener('hardwareBackPress', onExit)
    return () => BackHandler.removeEventListener('hardwareBackPress', onExit)
  }, [onExit])

  useEffect(() => {
    // Log to see if a post is missing a message,
    // since this might have caused the blank Intake issue.
    if (posts) {
      captureUndefinedMessages(posts, channelId)
    }
  }, [posts, channelId])

  const layoutConfig = useMemo(() => getLayoutConfig(questionPost), [questionPost])

  useLayoutEffect(() => {
    setQuestionShown(false)
    const horizontalTransition =
      questionPost?.props?.member_app_fullscreen?.transition_direction === 'horizontal'
      if (horizontalTransition) {
        // Disable animation for Android
        if (!isAndroid()) {
          // Note that this affects animations defined in other components
          LayoutAnimation.configureNext(horizontalAnimation)
        }
      } else {
        // Note that this affects animations defined in other components
        LayoutAnimation.configureNext({
          ...LayoutAnimation.Presets.spring,
          create: undefined,
          delete: undefined,
        })
      }
  }, [questionPost && questionPost.id])


  const { shrinkCard, shrinkReplyPanel, replyPanelNoGrow, overlayReplyForm } = layoutConfig
  const processing =
    (answerPosts &&
      answerPosts.length > 0 &&
      !answerPosts.some((ans) => ans.props && ans.props.failed)) ||
    !questionShown

  // Simulate loading steps when a loading config is available
  const [currentStepIndex, setCurrentStepIndex] = useState(1)
  const loadingConfig = questionPost?.props?.member_app_fullscreen?.loading
  useEffect(() => {
    if (loadingConfig && currentStepIndex < loadingConfig.steps.length + 1) {
      setTimeout(() => {
        setCurrentStepIndex(currentStepIndex + 1)
      }, Math.random() * 1000 + 500)
    }
  }, [loadingConfig, setCurrentStepIndex, currentStepIndex])

  const displayLoadingAnswer = loadingConfig && currentStepIndex > loadingConfig.steps.length

  const pulsating = questionPost?.props?.member_app_fullscreen?.pulsating
  const WrapperView = pulsating ? IntakePulsatingView : View

  const isPromptMultiSubprompt = questionPost?.props?.type == 'prompt_multi_subprompt'

  const onGoBack = () => {
    const goBackRunning = episode?.goBack?.running
    const postAlreadyUndone = undonePostIds?.indexOf(questionPost?.id) !== -1

    // Whether we're archiving or undoing a post, we prevent another back event
    // from being triggered, as they can interfere with one another.
    if (archiveEpisodeRunning || goBackRunning || postAlreadyUndone) {
      return
    }

    if (backButtonAction === ARCHIVE) {
      onArchiveEpisode()
      return
    }

    if (backButtonAction === UNDO) {
      dispatch(PatientHistoryActions.goBackInEpisodeRequest(channelId, questionPost?.id))
    }
  }

  return (
    <IntakeProvider>
      <WrapperView style={Styles.container}>
        <Card shrink={shrinkCard} questionShown={questionShown}>
          <Header
            fullscreenState={fullscreenState}
            processing={processing}
            onExit={onExit}
            onGoBack={onGoBack}
            canGoBack={canGoBack}
          />
          {!error && (
            <>
              <IntakeQuestion
                // Why are we overriding this, and then rendering the same thing in the reply panel?
                // Messes up lots of things relating to animation
                post={!isPromptMultiSubprompt ? questionPost : null}
                onQuestionShow={onQuestionShow}
                currentStepIndex={currentStepIndex}
                multiPromptUtteranceKey={
                  isPromptMultiSubprompt ? questionPost?.props?.utterance : null
                }
                // Have to pass it down for exit animation, as the post isn't supplied when it's a multi sub prompt.
                // Need to understand why we're hacking around the base functionality for rendering the question
                isReversedTransition={
                  questionPost?.props?.member_app_fullscreen?.transition_reversed
                }
              />
              <IntakeReplyPanel
                shrink={shrinkReplyPanel}
                noGrow={replyPanelNoGrow}
                overlayReplyForm={overlayReplyForm}
                showReplyPanel={loadingConfig ? displayLoadingAnswer : questionShown}
                onAnswerSend={onIntakeAnswerSend}
                answers={answerPosts}
                post={questionPost}
                retryPost={retryPost}
                resolveAttachmentUrl={resolveAttachmentUrl}
                resolveGetPostFileMetadata={resolveGetPostFileMetadata}
              />
            </>
          )}
        </Card>
        {isPromptMultiSubprompt && (
          <ResponsiveView style={Styles.actionBar}>
            <PortalPreview name="multiSubPromptSubmitPortal" />
          </ResponsiveView>
        )}
      </WrapperView>
      {enableArchiveEpisode && !hasExitAlert(exitAlert) && (
        <IntakeCancelChat
          isVisible={displayCancelChatModal}
          onCancelChat={onArchiveEpisode}
          onCloseModal={onCloseModal}
          onSaveChat={onSaveChat}
          isCancelling={archiveEpisodeRunning}
        />
      )}
    </IntakeProvider>
  )
}

export default Intake
