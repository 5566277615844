import React from 'react'
import { createNavigationContainerRef, NavigationContainer } from '@react-navigation/native'
import { useTheme } from 'styled-components/native'

// Config
import Config from 'APP/Config'

// Services & Helpers
import Analytics from 'APP/Services/Analytics'
import { createStatusBarStyleSetter } from 'APP/Nav/StatusBar'
import { ANALYTICS_SCREEN_BLACKLIST } from 'APP/Nav'
import { createScreenFocusTracker, getDdViewContext, routeNameToAnalyticsName } from 'APP/Nav/Utils'
import { startDdView } from 'APP/Lib/Datadog'

export const navigationRef = createNavigationContainerRef()
export const setStatusBarStyle = createStatusBarStyleSetter()

// Visible for testing
export const onFocus = (route) => {
  const shouldBeTracked = ANALYTICS_SCREEN_BLACKLIST.indexOf(route.name) === -1
  if (shouldBeTracked) {
    const analyticsName = routeNameToAnalyticsName(route.name)
    Analytics.trackScreen(analyticsName)
  }

  // Start tracking the view
  startDdView(route.key ?? route.name, route.name, getDdViewContext(route.params))
}

const screenFocusTracker = createScreenFocusTracker(navigationRef, {
  focus: onFocus,
})

const onStateChange = (state) => {
  screenFocusTracker(state)

  /*

      TODO: Revisit the following when we upgrade expo.
      Update: (June 26 2023): We're on the expo version which supports statusBarStyle, so we can do it now

      Note:
      React-navigation supports statusbar style control out of box, using statusBarStyle prop:
      https://reactnavigation.org/docs/7.x/native-stack-navigator/#statusbarstyle

      However, it requires setting UIViewControllerBasedStatusBarAppearance to
      true in Info.plist, which causes a conflict with our current configuration.
      Looks like we need to update to latest RN.

      See also:
      https://github.com/react-navigation/react-navigation/issues/7916
      https://stackoverflow.com/questions/66489117/how-to-fix-rctstatusbarmanager-module-requires-that-the-uiviewcontrollerbasedst

      In the meanwhile, the following fills the gap by manually controlling it.
      Using __statusBarStyle to prevent the warning from popping on debugger.

    */

  const { __statusBarStyle } = navigationRef?.getCurrentOptions() || {}
  setStatusBarStyle(__statusBarStyle)
}

export const documentTitle = {
  formatter: () => Config.PRODUCT_NAME_WEB,
}

const navContainerProps = {
  ref: navigationRef,
  onStateChange,
  documentTitle,
}

// TODO: Enable automatic view tracking and remove startDdView - https://docs.datadoghq.com/real_user_monitoring/mobile_and_tv_monitoring/react_native/setup/expo#automatic-tracking
export const NavProvider = (props = {}) => {
  const theme = useTheme()
  const themedProps = { ...props, theme }

  return <NavigationContainer {...navContainerProps} {...themedProps} />
}
