import React from 'react'
import { useNavigation } from '@react-navigation/native'
import { useSelector } from 'react-redux'
import Config from 'APP/Config'
import I18n from 'APP/Services/i18n'

import { selectEpharmacyExternalServicesByProvider } from 'APP/Redux/PatientRedux'

import { selectPharmacies } from 'APP/Store/Pharmacies/selectors'

import ListItem from 'APP/Converse/ListItem'

const PharmacyAccountMenuItem = () => {
  const navigation = useNavigation()
  const epharmacyPillwayExternalServices = useSelector((state) =>
    // @ts-expect-error: this method definitely allows two arguments
    selectEpharmacyExternalServicesByProvider(state, 'pillway')
  )
  const pharmacies = useSelector(selectPharmacies)

  // @ts-expect-error: scribe is not typed in MA yet
  const goToPharmacyAccount = (pharmacy) => {
    if (pharmacy.attributes.epharmacyProvider === 'pillway') {
      // Fetch province of epharmacy health resource from pharmacy list
      const province = pharmacies.find(
        (p) => p.external_id === pharmacy.attributes.epharmacyIdentifier
      )?.admin_area_level_1_iso_code

      // Generate full Pillway URL
      const navigationUrl = `${Config.PILLWAY_DOMAIN}/#/account?lang=${I18n.baseLocale}&loc=${province}`

      // Navigate to Pillway component
      // @ts-expect-error: navigate() does not seem to be typed properly
      navigation.navigate('pillwayPortalScreen', {
        navigationUrl,
      })
    }
  }

  return (
    <>
      {
        // @ts-expect-error: scribe is not typed in MA yet
        epharmacyPillwayExternalServices?.map((pharmacy) => (
          <ListItem
            key={`pharmacy-account-${pharmacy.id}`}
            analyticsName="pharmacy-account"
            testID="PHARMACY_ACCOUNT_SCREEN_PHARMACY_MENU_ITEM"
            title={I18n.t('AccountScreen.pharmacyAccount')}
            onPress={() => goToPharmacyAccount(pharmacy)}
          />
        ))
      }
    </>
  )
}

export default PharmacyAccountMenuItem
