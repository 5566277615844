import React from 'react'

import { STACK_SIDE_MENUS, ANALYTICS_NAME_MAP } from 'APP/Nav'

export const selectDescriptorOfCurrentRoute = (descriptors = {}, stackRoutes = []) => {
  const route = stackRoutes[stackRoutes.length - 1]
  return descriptors[route?.key]
}

export const createScreenFocusTracker = (navigationRef, { focus, blur }) => {
  let prevRoute = ''
  return () => {
    const currentRoute = navigationRef.getCurrentRoute()
    if (prevRoute !== currentRoute) {
      typeof focus === 'function' && focus(currentRoute)
      typeof blur === 'function' && prevRoute && blur(prevRoute)
    }
    prevRoute = currentRoute
  }
}

export const routeNameToAnalyticsName = (name) => ANALYTICS_NAME_MAP[name] || name

export const routesToStackSideMenus = (stackRoutes = []) =>
  stackRoutes
    .map(({ name, key }, stackIndex) => {
      const Menu = STACK_SIDE_MENUS[name]
      return Menu && <Menu key={key} {...{ stackRoutes, stackIndex }} />
    })
    .filter((item) => !!item)

// Map of route parameters to keep in the context.
// The values are the keys that should be used in the context.
const DD_VIEW_CONTEXT_PARAM_MAP = new Map([
  ['channelId', 'episode_id'],
  ['episodeId', 'episode_id'],
  ['videoSessionId', 'video_call_session_id'],
])

export const getDdViewContext = (routeParams) => {
  const context = {}
  if (!routeParams) return context

  for (let paramKey in routeParams) {
    const contextKey = DD_VIEW_CONTEXT_PARAM_MAP.get(paramKey)
    if (contextKey) {
      context[contextKey] = routeParams[paramKey]
    }
  }

  return context
}
