import { Linking, Platform } from 'react-native'
import { delay } from 'redux-saga/effects'
import { call, put, select } from 'typed-redux-saga'

import { Toktok } from '@dialogue/services'
import { videoSessionActions } from './slice'
import * as StoreReview from 'expo-store-review'

import Alert from 'APP/Converse/Alert'

import Config from 'APP/Config'
import I18n from 'APP/Services/i18n'
import { navigationRef as Nav } from 'APP/Nav'
import * as NestedNavHelper from 'APP/Nav/NestedNavHelper'
import { trackDdEvent } from 'APP/Lib/Datadog'

// Api
import {
  checkCameraState,
  requestCameraPermission,
  checkMicrophoneState,
  requestMicrophonePermission,
  checkBluetoothConnectState,
  requestBluetoothConnectPermission,
} from 'APP/NativeModules/PermissionsService'
import Analytics from 'APP/Services/Analytics'

import { selectPractitionerId } from './selectors'
import type { RootState } from 'APP/Store/CreateStore'
import { isMobile } from 'APP/Helpers/checkPlatform'

import LoggerActions from 'APP/Redux/LoggerRedux'

export const selectProfile = (state: RootState) => state.patient && state.patient.profile
export const selectPractitionersList = (state: RootState) => state.history.practitioners || {}
export const selectLogin = (state: RootState) => state.login
export const selectLastCallAcceptedAt = (state: RootState) =>
  state.videoSession?.lastCallAcceptedAt || 0
export const selectFeatures = (state: RootState) => state.features
export const selectLastVideoCall = (state: RootState) => state.videoSession?.lastVideoCall
export const selectVideoSessionId = (state: RootState) => state.videoSession?.sessionId

export const Types = {
  CONNECT_TO_OPENTOK: 'CONNECT_TO_OPENTOK',
}

export const Actions = {
  connectToOpentok: () => ({
    type: Types.CONNECT_TO_OPENTOK,
  }),
}

const VIDEO_CALL_ENDED_TYPES = [
  'videoSession/patientEndedCall',
  'videoSession/platformEndedCall',
  'videoSession/platformStreamDestroyed',
]

export function* getCaller(id: string | undefined) {
  const practitionerId = id ? id : yield* select(selectPractitionerId)
  if (!practitionerId) return {}
  const practitioners = Object.values(yield select(selectPractitionersList))
  return practitioners.find(
    (practitioner) => String(practitioner.app_id) === String(practitionerId)
  )
}

function showAppSettingsAlert() {
  const cta = isMobile()
    ? [
        {
          text: I18n.t('IncomingCallScreen.permissionCTA'),
          onPress: () => Linking.openSettings(),
        },
      ]
    : [
        {
          text: I18n.t('IncomingCallScreen.bluetoothConnectDisclaimerCta'),
        },
      ]

  Alert.alert('', I18n.t('IncomingCallScreen.permissionToConnect'), cta)
}

export function* disconnect() {
  yield put(videoSessionActions.disconnectFromSession())
}

export function* trackVideoQualityStats({ current, previous }) {
  const eventProps = { value: current, previous_value: previous }
  yield call(Analytics.trackEvent, 'video_quality_change', eventProps)
}

export function* connectToOpenTok() {
  const patientProfile = yield* select(selectProfile)
  const loginStore = yield* select(selectLogin)
  const accessToken = loginStore?.accessToken

  // Skip if not signed in
  // Skip if not provided in config
  if (!patientProfile?.id || !accessToken || !Config.TOKTOK_SERVICE_URL) return

  try {
    const toktokClient = new Toktok(accessToken, Config.TOKTOK_SERVICE_URL)
    const toktokResult = yield* call(toktokClient.getSelfSessionToken)

    console.log('connecting to opentok')
    yield put(
      videoSessionActions.connectToSession({
        apiKey: toktokResult.data.api_key,
        sessionId: toktokResult.data.session_id,
        token: toktokResult.data.token,
      })
    )

    console.log('connected to opentok')
  } catch (e) {
    console.warn('error connecting to opentok. will retry in 5 seconds', e)
    console.trace()

    yield put(Actions.connectToOpentok())
  }
}

export const isVideoCallRoute = () =>
  ['incomingCall', 'videoCall'].indexOf(Nav.getCurrentRoute()?.name) !== -1

export function* incomingCall() {
  const patientProfile = yield* select(selectProfile)

  const caller = yield* call(getCaller)

  if (!isVideoCallRoute()) {
    if (Nav.getCurrentRoute()?.name === 'videoCallEnded') {
      // Close the current videoCallEnded screen before navigating to new incomingCall
      yield call(NestedNavHelper.pop)
    }

    // We pass the `videoSessionId` so it's tracked for DD rum
    const videoSessionId = yield* select(selectVideoSessionId)
    yield call(Nav.navigate, 'incomingCall', { caller, videoSessionId: videoSessionId })
    yield put(videoSessionActions.patientRinging({ userId: patientProfile.id }))
  }
}

export function* shouldPromptForAppRating() {
  const { enableAfterCallAppRatingPrompt } = yield select(selectFeatures)
  const deviceSupportsInAppReview = yield* call(StoreReview.isAvailableAsync)
  const acceptedAt = yield* select(selectLastCallAcceptedAt)
  const durationThresholdMet = Date.now() - acceptedAt >= Config.APP_REVIEW_MIN_CALL_DURATION
  return (
    !(yield* call(isVideoCallRoute)) &&
    (yield* call(isMobile)) &&
    deviceSupportsInAppReview &&
    enableAfterCallAppRatingPrompt &&
    durationThresholdMet
  )
}

export function* afterCallAppRatingPromptCheck() {
  try {
    const shouldPrompt = yield* call(shouldPromptForAppRating)
    if (shouldPrompt) {
      yield delay(Config.APP_REVIEW_PROMPT_DELAY)
      yield call(StoreReview.requestReview)
      yield put(LoggerActions.log('Triggered App Review Prompt'))
    }
  } catch (e) {
    const { code } = e as { code: string }
    if (code === 'ERR_R_MUNSUCCESSFUL_TASK') {
      yield put(
        LoggerActions.log('Unable to complete In-App review (play services likely not installed)')
      )
    } else {
      yield put(LoggerActions.error(`Error triggering In-App review ${JSON.stringify(e)}`))
    }
  }
}

export function* dismissVideoCallScreen(action) {
  if (isVideoCallRoute()) {
    if (Nav.getRootState()?.routes?.length <= 1) {
      yield call(Nav.reset, { index: 0, routes: [{ name: 'tabbar' }] })
    } else {
      const memberDeclinedCall =
        action?.type === 'videoSession/patientEndedCall' && action?.payload?.declined

      if (VIDEO_CALL_ENDED_TYPES.includes(action?.type)) {
        const lastVideoCall = yield* select(selectLastVideoCall)
        const { practitionerId, episodeId } = lastVideoCall
        const caller = yield* call(getCaller, practitionerId)

        if (memberDeclinedCall) {
          yield call(trackDdEvent, 'video-call:declined')
          yield call(NestedNavHelper.replace, 'conversation', { channelId: episodeId })
        } else {
          yield call(trackDdEvent, 'video-call:end')
          yield call(NestedNavHelper.replace, 'videoCallEnded', { caller, episodeId })
        }
      } else {
        yield call(NestedNavHelper.pop)
      }
    }
  }
}

export function showBluetoothAlert() {
  return new Promise((resolve) => {
    Alert.alert(
      '',
      I18n.t('IncomingCallScreen.bluetoothConnectDisclaimer') as string,
      [
        {
          text: I18n.t('IncomingCallScreen.bluetoothConnectDisclaimerCta') as string,
          onPress: () => resolve('ok'),
        },
      ],
      {
        cancelable: false,
      }
    )
  })
}

export function* patientAcceptedCall() {
  const patientProfile = yield* select(selectProfile)

  yield call(trackDdEvent, 'video-call:accepted')

  // Check audio/video permissions first
  let cameraPermission = yield* call(checkCameraState)
  let microphonePermission = yield* call(checkMicrophoneState)

  // Android 12 introduces Bluetooth permissions
  const shouldAskForBluetoothConnect = Platform.OS === 'android' && Platform.Version >= 31
  let bluetoothConnectPermission = yield* call(checkBluetoothConnectState)

  if (cameraPermission !== 'granted') {
    yield put(videoSessionActions.patientAskingVideoPermission({ userId: patientProfile.id }))

    cameraPermission = yield* call(requestCameraPermission)
    if (cameraPermission === 'granted') {
      yield put(videoSessionActions.patientAcceptedVideoPermission({ userId: patientProfile.id }))
    } else {
      yield put(videoSessionActions.patientDeclinedVideoPermission({ userId: patientProfile.id }))
      yield call(dismissVideoCallScreen)
      showAppSettingsAlert()
      return
    }
  }

  if (microphonePermission !== 'granted') {
    yield put(videoSessionActions.patientAskingAudioPermission({ userId: patientProfile.id }))

    microphonePermission = yield* call(requestMicrophonePermission)

    if (microphonePermission === 'granted' || microphonePermission === 'unavailable') {
      // Microphone permissions are never asked on iOS
      yield put(videoSessionActions.patientAcceptedAudioPermission({ userId: patientProfile.id }))
    } else {
      yield put(videoSessionActions.patientDeclinedAudioPermission({ userId: patientProfile.id }))
      yield call(dismissVideoCallScreen)
      showAppSettingsAlert()
      return
    }
  }

  // Bluetooth Connect permission is only available on Android 12+, and this is asked once
  if (shouldAskForBluetoothConnect && bluetoothConnectPermission === 'denied') {
    bluetoothConnectPermission = yield* call(requestBluetoothConnectPermission)

    if (bluetoothConnectPermission === 'denied') {
      yield call(showBluetoothAlert)
    }
  }

  const caller = yield* call(getCaller)
  const videoSessionId = yield* select(selectVideoSessionId)
  // Navigate to Video Call
  // We pass the `videoSessionId` so it's tracked for DD rum
  yield call(NestedNavHelper.replace, 'videoCall', { caller, videoSessionId: videoSessionId })
}
