import { StyleSheet } from 'react-native'
import { Colors, Metrics, ApplicationStyles, Fonts } from 'APP/Themes'
import { isWeb } from 'APP/Helpers/checkPlatform'

export const Styles = StyleSheet.create({
  ...ApplicationStyles.screen,
  container: {
    backgroundColor: Colors.appBg,
    flex: 1,
    zIndex: 1,
  },
  containerLocked: {
    ...StyleSheet.absoluteFill,
    height: Metrics.screenHeight,
  },
  headerContainer: {
    display: 'flex',
    paddingTop: Metrics.statusBarHeight + 10,
    zIndex: 2,
    width: '100%',
    flexGrow: 1,
    flexShrink: 0,
    alignItems: 'flex-end',
    paddingHorizontal: isWeb() ? 0 : Metrics.baseMargin + 4,
  },
  iconContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
  },
  iconButton: {
    display: 'flex',
    height: Fonts.size.h2 * 1.5,
    width: Fonts.size.h2 * 1.5,
    marginVertical: Metrics.baseMargin / 2,
    borderRadius: 35,
    justifyContent: 'center',
    alignItems: 'center',
  },
  card: {
    flexShrink: 1,
    zIndex: 2,
    flexBasis: Metrics.screenHeight,
    // To prevent jumping around during transitions which cause overflow.
    ...(isWeb() && { scrollbarWidth: 'none' }),
  },
  cardContent: {
    flexGrow: 1,
    flexShrink: 0,
    justifyContent: 'space-between',
    width: '100%',
    maxWidth: 800,
    alignSelf: 'center',
  },
  cardContentShrink: {
    flexShrink: 1,
  },
  bottomModalContainer: {
    paddingHorizontal: Metrics.baseMargin * 2,
    paddingBottom: Metrics.bottomSpace || Metrics.baseMargin,
  },
  actionBar: {
    paddingTop: Metrics.baseMargin * 1.5,
  },
})
